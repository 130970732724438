body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  -webkit-overflow-scrolling: auto;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

a {
  color: black;
  font-family: "GT-America-Extended-Black";
}

a:link,
a:visited,
a:hover,
a:active,
a:focus {
  color: black;
  font-family: "GT-America-Extended-Black", Times, serif;
}
