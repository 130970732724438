.Project {
  width: 100%;
  overflow: hidden;
  height: fit-content;
}

.Project .Title {
  cursor: pointer;
  width: 100%;
  height: 25px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  transition: gap 0.2s ease-out;
}

.Project .Title:hover {
  gap: 8px;
}

.Project .Title .TitleIcon {
  height: 1em;
  width: 1em;
}

.Project .Description {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-top: 2px;
  padding-left: 20px;
  padding-bottom: 25px;
}

.Project .Description .ProjectLink {
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.Project .Description p {
  font-style: italic;
  margin: 0px !important;
  padding: 0px !important;
}
