.AddBlogView {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.AddBlogView .WriteBlog {
  flex-basis: 40%;
  height: 80%;
}

.AddBlogView .ViewBlog {
  flex-basis: 40%;
  height: 80%;
  position: relative;
}

.AddBlogView .ViewMarkdown {
  border: 1px solid lightgray;
  padding: 16.5px 14px;
  border-radius: 4px;
  height: 80%;
  overflow-y: scroll;
}

.AddBlogView .WriteBlog .WriteBlogHeader {
  display: flex;
  align-items: center;
  gap: 30px;
}
