.DailyBlog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: fit-content;
  width: 400px;
  padding-left: 10px;
  padding-right: 10px;
}

.SpecialBlog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: fit-content;
  width: 600px;
  padding-left: 10px;
  padding-right: 10px;
}

.Blog .Markdown {
  width: 100%;
}

.Blog .Markdown p {
  font-family: "Times New Roman", Times, serif;
  margin-top: 10px;
  margin-bottom: 30px;
}

.Blog h2 {
  margin: 0;
}

code[class*="language-"],
pre[class*="language-"] {
  font-size: 12px;
}
